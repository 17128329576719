<template>
  <div
    :class="{
      'user-layout__alert': true,
      'user-layout__alert--fluid': fluidLayout
    }"
  >
    <Alert variant="warning">
      <div class="w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/images/layout/alerts/icons/info-warning.svg"
              alt="info warning"
            />
            <p class="mb-0 user-layout__alert--content">
              {{ $t("mfa.configuration.heading") }}
            </p>
          </div>
          <Button variant="warning" pill @click="setupMfa">{{
            $t("general.setup")
          }}</Button>
        </div>
      </div>
    </Alert>

    <Modals @onSetup="setupMfa()" />
  </div>
</template>
<script>
import Alert from "@/components/WrapperComponents/Alert.vue";
import Button from "@/components/WrapperComponents/Button.vue";
import { mapGetters } from "vuex";
import COMMAN_MODAL_IDS from "@/components/Shared/CommonModalConstants";
import Modals from "@/components/Shared/Modals";

export default {
  props: {
    fluidLayout: {
      type: Boolean,
      default: false
    }
  },
  components: { Alert, Button, Modals },
  computed: {
    ...mapGetters(["allConfig"])
  },
  methods: {
    setupMfa() {
      this.$store.dispatch("configureMfa").then(() => {
        this.$bvModal.show(COMMAN_MODAL_IDS.MFA_SUCCESS_MODAL);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.user-layout__alert {
  &.user-layout__alert--fluid {
    margin: 18px 40px 0;
  }
  margin: 0 0 18px;
  .alert {
    padding: 9px 16px;
    margin-bottom: 0;
  }
  .user-layout__alert--content {
    @include body-medium;
  }
}
</style>
