<template>
  <div
    class="user-fluid-layout__side-nav d-flex align-items-start flex-column tour-style"
  >
    <div
      id="userFluidSidebar"
      class="user-fluid-layout__side-nav-menu"
      :class="{ expand: expandSideNav }"
    >
      <ul>
        <template v-for="(a, i) in menu">
          <li
            :key="`menu-${i}`"
            class="d-flex align-items-center justify-content-start"
            v-b-toggle="`menu-${i}`"
            :class="{ active: a.active, 'hover-item': a.hasSubmenu }"
            @click="handleOnClick(a)"
            @mouseover="handleOnHover(a)"
            @mouseleave="handleOnLeave(a)"
          >
            <img
              class="menu-icon"
              :src="a.active ? a.activeSrc : a.mouseOver ? a.hoverSrc : a.src"
              :alt="a.name"
            />
            <span v-show="expandSideNav">{{ a.name }}</span>
            <div
              class="user-fluid-layout__side-nav-arrow"
              v-if="expandSideNav && a.hasSubmenu"
            >
              <img :src="arrowSrc" alt="arrow" />
            </div>
            <div class="submenu-dropdown" v-if="!expandSideNav && a.hasSubmenu">
              <ul class="sub-menu">
                <li
                  v-for="(s, i) in a.submenuArr"
                  :key="`submenu-${i}`"
                  :class="{ active: s.active }"
                  @click="$emit('navigateTo', s.path)"
                  @mouseover="s.mouseOver = true"
                  @mouseleave="s.mouseOver = false"
                >
                  {{ s.name }}
                </li>
              </ul>
            </div>
          </li>
          <b-collapse
            :key="i"
            v-if="expandSideNav && a.hasSubmenu"
            :id="`menu-${i}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <ul class="sub-menu">
              <li
                v-for="(s, i) in a.submenuArr"
                :key="`submenu-${i}`"
                :class="{ active: s.active }"
                @click="$emit('navigateTo', s.path)"
                @mouseover="s.mouseOver = true"
                @mouseleave="s.mouseOver = false"
              >
                {{ s.name }}
              </li>
            </ul>
          </b-collapse>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    expandSideNav: {
      type: Boolean,
      required: true
    },
    menu: {
      type: Array,
      required: true
    },
    termsAndConditionsUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chevronUp: require("@/assets/images/vle/arrow-up.svg"),
      chevronDown: require("@/assets/images/vle/arrow-down.svg"),
      hoverChevronUp: require("@/assets/images/vle/hover-arrow-up.svg"),
      hoverChevronDown: require("@/assets/images/vle/hover-arrow-down.svg"),
      visible: false,
      hoverElement: false
    };
  },
  computed: {
    arrowSrc() {
      if (this.visible) {
        return this.hoverElement ? this.hoverChevronUp : this.chevronUp;
      } else {
        return this.hoverElement ? this.hoverChevronDown : this.chevronDown;
      }
    }
  },
  methods: {
    handleOnHover(obj) {
      this.$emit("updateOnHover", { obj, value: true });
      this.hoverElement = true;
    },
    handleOnLeave(obj) {
      this.$emit("updateOnHover", { obj, value: false });
      this.hoverElement = false;
    },
    handleOnClick(a) {
      if (!a.hasSubmenu) {
        this.$emit("navigateTo", a.path);
      }
      if (this.expandSideNav && a.hasSubmenu) {
        this.visible = !this.visible;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.user-fluid-layout__side-nav {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 10px rgba(9, 44, 78, 0.03),
    0px 9px 30px rgba(9, 44, 78, 0.05);
  border-radius: 4px;
  background: #fff;
  .user-fluid-layout__side-nav-menu {
    text-align: left;
    width: 100%;
    overflow: hidden auto;
    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    ul {
      @include center;
      margin: 10px 0px 15px;
      &.sub-menu {
        margin: 0 0 0 28px;
      }
    }
    li {
      cursor: pointer;
      min-height: 40px;
      padding: 11px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $brand-primary;
      margin: 0px auto 16px;
      letter-spacing: 0.1px;
      &:hover {
        color: $brand-primary-400;
        background: $ufl-search-bg;
        mix-blend-mode: multiply;
      }
      &.active {
        background: $brand-primary;
        color: #fff;
      }
      .menu-icon {
        margin-right: 0;
        width: 20px;
        height: 20px;
      }
    }
    &.expand {
      width: calc(100% - 16px);
      margin: 0 auto;
      li {
        width: 100%;
        .menu-icon {
          margin-right: 13px;
        }
      }
    }
    .user-fluid-layout__side-nav-arrow {
      margin-left: auto;
    }
  }
  .user-fluid-layout__side-nav-footer {
    padding: 15px 0;
    border-top: 1px solid #e5e5e5;
    font-size: 11px;
    line-height: 18px;
    color: #757575;
    width: inherit;
    a {
      margin-top: 8px;
      display: block;
    }
  }
}
.v-tour--active {
  .user-fluid-layout__side-nav {
    background: none;
    z-index: 99;
    .user-fluid-layout__side-nav-menu {
      opacity: 0.3;
    }
    #userFluidSidebar {
      &.v-tour__target--highlighted {
        background: $brand-neutral-0;
        border-radius: 16px;
        opacity: 1;
        &.expand {
          width: 185px;
          padding: 0 8px;
        }
      }
    }
    .user-fluid-layout__side-nav-footer {
      border-color: transparent;
      a {
        color: $brand-default-text;
      }
    }
  }
}

[dir="rtl"] {
  .user-fluid-layout__side-nav {
    .user-fluid-layout__side-nav-menu {
      &.expand {
        li {
          img {
            margin-right: 0;
            margin-left: 13px;
          }
        }
      }
      .user-fluid-layout__side-nav-arrow {
        margin-right: auto;
        margin-left: 0px;
      }
    }
  }
}
</style>
